<script setup>
import { useQuasar } from 'quasar';
import { computed } from 'vue';

const quasar = useQuasar();

const buttonIcon = computed(() => {
    if (quasar.dark.mode === true) return 'sym_s_dark_mode';
    if (quasar.dark.mode === false) return 'sym_s_light_mode';
    return 'sym_s_auto_mode';
});

const buttonPadding = computed(() => {
    if (quasar.dark.mode === true) return '6px';
    return '7px 6px 5px 6px';
})

function toggleThemeMode() {
    const modes = [ false, 'auto', true ];
    const curIndex = modes.indexOf(quasar.dark.mode);
    const nextIndex = (curIndex + 1) % modes.length;
    quasar.dark.set(modes[nextIndex]);
}
</script>

<template>
    <div class="theme-mode-toggle-button">
        <q-no-ssr>
            <q-btn @click="toggleThemeMode" :icon="buttonIcon" :padding="buttonPadding" title="Toggle theme mode" color="primary" outline/>
        </q-no-ssr>
    </div>
</template>

<style scoped lang="scss">

</style>
