<script setup>
import AppFooter from 'components/AppFooter.vue';
import AppLogo from 'components/AppLogo.vue';
import NavItem from 'components/NavItem.vue';
import ThemeModeToggleButton from 'components/ThemeModeToggleButton.vue';
import { useQuasar } from 'quasar';
import { useAuthManager } from 'src/composables/auth-manager';
import AppRoute from 'src/router/app-route';
import { Route } from 'src/router/routes';
import { openComponentDialog } from 'src/utils/quasar-util';
import { useAppStore } from 'stores/app-store';
import { useAuthStore } from 'stores/auth-store.js';
import { useChatStore } from 'stores/chat-store';
import { computed, defineAsyncComponent, onMounted, onUnmounted, ref } from 'vue';
import { useRoute } from 'vue-router';

const UserMenuToggle = defineAsyncComponent(() => import('components/UserMenuToggle.vue'));

const q = useQuasar();
const route = useRoute();
const appStore = useAppStore();
const authManager = useAuthManager();
const authStore = useAuthStore();
const chatStore = useChatStore();

const scrollPosition = ref(0);

const bannerComponent = computed(() => {
    if (process.env.SERVER || route.name === Route.Home || userIsInAppContext.value) {
        return null;
    }
    if (authStore.userAuthenticated) {
        const bannerComponents = [
            defineAsyncComponent(() => import('components/banner/KnowSomeGreatEngineersBanner.vue')),
            defineAsyncComponent(() => import('components/banner/LevelUpYourXprtGameBanner.vue')),
            defineAsyncComponent(() => import('components/banner/WantMoreEyesOnYourProfileBanner.vue'))
        ];
        return bannerComponents[Math.floor(Math.random() * bannerComponents.length)];
    }
    if (route.name === Route.PublicUserProfileByAlias || route.name === Route.PublicUserProfileByToken) {
        return defineAsyncComponent(() => import('components/banner/TimeToUpgradeYourPaperCVBanner.vue'));
    }
    return defineAsyncComponent(() => import('components/banner/ReadyToBecomeAnXprtBanner.vue'));
});

const userIsInAppContext = computed(() => {
    return route.matched[0]?.path === '/app';
});

const topNavLinks = computed(() => {
    return [
        {
            title: 'Home',
            icon: 'sym_s_home',
            route: AppRoute.build(Route.Home),
            visible: !userIsInAppContext.value,
            groupIndex: 0,
            exactRoute: true
        },
        {
            title: 'Why Xprt?',
            icon: 'sym_s_info',
            route: AppRoute.build(Route.AboutUs),
            visible: !userIsInAppContext.value,
            groupIndex: 0
        },
        {
            title: 'FAQs',
            icon: 'sym_s_quiz',
            route: AppRoute.build(Route.FAQs),
            visible: !userIsInAppContext.value,
            groupIndex: 0
        },
        {
            title: 'Dashboard',
            icon: 'sym_s_dashboard',
            route: AppRoute.build(Route.Dashboard),
            visible: userIsInAppContext.value && authStore.userAuthenticated,
            groupIndex: 1,
            exactRoute: true
        },
        {
            title: 'Profile',
            icon: 'sym_s_account_box',
            route: AppRoute.build(Route.ManageUserProfile),
            visible: userIsInAppContext.value && authStore.userAuthenticated,
            groupIndex: 1
        }, {
            title: 'Messages',
            icon: 'sym_s_chat',
            route: AppRoute.build(Route.Chats),
            count: chatStore.totalUnreadChatMessages,
            visible: userIsInAppContext.value && authStore.userAuthenticated,
            groupIndex: 1
        }, {
            title: 'People',
            icon: 'sym_s_person_search',
            route: AppRoute.build(Route.People),
            visible: userIsInAppContext.value && authStore.userAuthenticated,
            groupIndex: 1,
            comingSoonLabel: true
        }, {
            title: 'Jobs',
            icon: 'sym_s_work',
            route: AppRoute.build(Route.Jobs),
            visible: userIsInAppContext.value && authStore.userAuthenticated,
            groupIndex: 1,
            comingSoonLabel: true
        }
    ].filter(l => l.visible ?? false);
});

const sideNavLinks = computed(() => {
    return [
        ...topNavLinks.value,
        {
            title: 'Login / Sign Up',
            icon: 'sym_s_login',
            route: AppRoute.build(Route.Login),
            visible: !authStore.userAuthenticated,
            groupIndex: 2
        },
        {
            title: 'Return to App',
            icon: 'sym_s_exit_to_app',
            route: AppRoute.build(Route.Login),
            visible: !userIsInAppContext.value && authStore.userAuthenticated,
            groupIndex: 2
        },
        {
            title: 'Profile Links',
            icon: 'sym_s_captive_portal',
            route: AppRoute.build(Route.ManageProfileLinks),
            visible: userIsInAppContext.value && authStore.userAuthenticated && authStore.user.role === 'Xprt',
            groupIndex: 3
        },
        {
            title: 'Invite Xprts',
            icon: 'sym_s_share',
            route: AppRoute.build(Route.ManageInviteLinks),
            visible: userIsInAppContext.value && authStore.userAuthenticated,
            groupIndex: 3
        },
        {
            title: 'Help',
            icon: 'sym_s_help_center',
            route: () => openComponentDialog(defineAsyncComponent(() => import('components/HelpDialog.vue'))),
            visible: userIsInAppContext.value && authStore.userAuthenticated,
            groupIndex: 4
        },
        {
            title: 'Logout',
            icon: 'sym_s_logout',
            route: () => authManager.openLogout(),
            visible: userIsInAppContext.value && authStore.userAuthenticated,
            groupIndex: 5
        }
    ].filter(l => l.visible ?? false);
});

const isTopNavVisible = computed(() => q.screen.md || q.screen.gt.md);

function handleScroll() {
    scrollPosition.value = window.scrollY;
}

function toggleDrawerIsOpen() {
    appStore.drawerIsOpen = !appStore.drawerIsOpen;
}

function openUserMenuDialog() {
    openComponentDialog(defineAsyncComponent(() => import('components/UserMenuDialog.vue')));
}

onMounted(() => {
    window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
});
</script>

<template>
    <q-layout view="hHh lpR fFf" id="main-layout">
        <q-header :class="[ 'main-header', { 'scrolled': scrollPosition > 40, 'drawer-open': appStore.drawerIsOpen } ]">
            <q-toolbar class="flex q-py-sm">
                <div class="side-container justify-start q-mr-md">
                    <q-btn
                        @click="toggleDrawerIsOpen"
                        icon="sym_s_menu"
                        class="drawer-toggle"
                        color="primary"
                        text-color="white"
                        aria-label="Menu"
                        padding="6px"
                        unelevated
                    />
                    <q-toolbar-title class="flex column justify-center items-start">
                        <AppLogo/>
                    </q-toolbar-title>
                </div>
                <div v-if="isTopNavVisible" class="nav-container top-nav text-center">
                    <q-list class="flex row justify-center">
                        <NavItem
                            v-for="link of topNavLinks.filter(l => l.visible ?? false)"
                            :link="link"
                            class="text-weight-medium"
                            hide-icons
                        />
                    </q-list>
                </div>
                <div class="side-container justify-end q-ml-sm q-gutter-x-sm">
                    <ThemeModeToggleButton/>
                    <q-btn
                        v-if="!userIsInAppContext"
                        :to="AppRoute.build(Route.Dashboard)"
                        icon="sym_s_exit_to_app"
                        color="primary"
                        title="Return to app"
                        padding="6px"
                        unelevated
                    />
                    <UserMenuToggle v-else @click="openUserMenuDialog" title="Open user menu" class="cursor-pointer"/>
                </div>
            </q-toolbar>
        </q-header>
        <q-drawer
            v-model="appStore.drawerIsOpen"
            :mini="!appStore.drawerIsOpen || appStore.drawerIsMinimized"
            :breakpoint="1030"
            side="left"
            class="q-px-sm"
            show-if-above
            overlay
        >
            <q-no-ssr>
                <q-scroll-area class="fit" :horizontal-thumb-style="{ opacity: 0 }">
                    <div class="overlay-header items-center">
                        <q-btn
                            @click="toggleDrawerIsOpen"
                            icon="sym_s_menu"
                            class="drawer-toggle"
                            color="primary"
                            text-color="white"
                            aria-label="Menu"
                            padding="6px"
                            unelevated
                        />
                        <q-toolbar-title class="flex column justify-center items-start">
                            <AppLogo/>
                        </q-toolbar-title>
                    </div>
                    <q-list class="q-gutter-y-sm q-mt-sm">
                        <template v-for="(link, index) of sideNavLinks">
                            <div v-if="index > 0 && link.groupIndex !== sideNavLinks[index - 1].groupIndex" class="q-my-lg"/>
                            <NavItem :link="link"/>
                        </template>
                    </q-list>
                </q-scroll-area>
            </q-no-ssr>
        </q-drawer>
        <q-page-container>
            <q-page class="flex column" :style-fn="o => ({ minHeight: o ? `calc(100vh - ${o}px)` : '100vh' })">
                <router-view name="dialog"/>
                <router-view/>
                <q-no-ssr>
                    <component :is="bannerComponent" v-if="bannerComponent"/>
                </q-no-ssr>
                <AppFooter/>
            </q-page>
        </q-page-container>
    </q-layout>
</template>

<style lang="scss" scoped>
.q-header {
    background: revert;
    transition: box-shadow 120ms ease-in-out;

    &.scrolled {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.20);
    }
}

.q-toolbar {
    backdrop-filter: blur(20px);
    background: rgba($white, 0.95);
    color: $primary;
    height: 62px;
    justify-content: space-between;

    .body--dark & {
        background: rgba($dark-page, 0.95) !important;
        color: $white;
    }

    .q-toolbar__title {
        padding: 0 8px;
    }

    .nav-container,
    .side-container {
        align-items: center;
        display: flex;
        flex: 1;
    }

    .nav-container {
        flex: 0 1 auto;
        justify-content: center;
    }
}

.overlay-header {
    display: none;

    .q-drawer--mobile & {
        display: flex;
        padding-top: 14px;
        padding-right: 4px;
        padding-left: 4px;
        padding-bottom: 14px;

        .q-toolbar__title {
            padding-left: 8px;
        }

        .body--dark & {
            border-bottom-color: $separator-dark-color;
        }
    }
}
</style>

<style lang="scss">
#main-layout {
    .q-drawer {
        body:not(.q-body--prevent-scroll) & {
            z-index: 998;
        }

        .q-item {
            background: none;
        }
    }

    .q-drawer,
    .q-toolbar {
        .app-logo {
            height: 36px;
            width: auto;
        }
    }

    .top-nav {
        .q-item {
            border-radius: 4px;
            height: 36px;
            margin-left: 4px;
            margin-right: 4px;
            min-height: auto;
            padding-bottom: 10px;
            padding-top: 10px;

            &.q-item--active,
            &.q-router-link--active:not(.highlight-exact-only),
            &.q-router-link--exact-active {
                color: $white;
            }

            &.q-router-link--active:not(.highlight-exact-only),
            &.q-router-link--exact-active {
                background: $primary;
            }

            .q-item__section {
                margin-left: 8px;
                margin-right: 8px;
            }

            .body--dark & {
                border-color: $separator-dark-color;
            }
        }
    }

    .q-drawer.q-drawer--left {
        &.q-drawer--on-top {
            backdrop-filter: blur(20px);
            background: rgba($white, 0.9);
            margin-top: -1px;

            .body--dark & {
                background: rgba($dark-page, 0.9);
            }
        }

        &.q-drawer--bordered {
            border-top: 1px solid $separator-color;

            .body--dark & {
                border-top-color: $separator-dark-color;
            }
        }
    }
}
</style>
