<script setup>
import AppLogo from 'components/AppLogo.vue';
import TextLink from 'components/TextLink.vue';
import AppRoute from 'src/router/app-route.js';
import ExternalRoute from 'src/router/external-route';
import { Route } from 'src/router/routes.js';
import { RequestType } from 'src/services/ticket-service';
import { openComponentDialog } from 'src/utils/quasar-util';
import { useAuthStore } from 'stores/auth-store.js';
import { defineAsyncComponent } from 'vue';

const authStore = useAuthStore();

const communityLinks = [
    {
        label: 'Why Xprt?',
        route: AppRoute.build(Route.AboutUs)
    },
    {
        label: 'Guidelines',
        route: AppRoute.build(Route.Guidelines)
    },
    {
        label: 'Request a Feature',
        route: () => {
            if (!authStore.userAuthenticated) {
                return openUserLoginDialog();
            }
            return openComponentDialog(defineAsyncComponent(() => import('components/TicketCreateDialog.vue')), {
                requestType: RequestType.FEATURE_REQUEST
            });
        }
    },
    {
        label: 'Report a Bug',
        route: () => {
            if (!authStore.userAuthenticated) {
                return openUserLoginDialog();
            }
            return openComponentDialog(defineAsyncComponent(() => import('components/TicketCreateDialog.vue')), {
                requestType: RequestType.BUG_REPORT
            });
        }
    },
    {
        label: 'FAQs',
        route: AppRoute.build(Route.FAQs)
    }
];

const companyLinks = [
    {
        label: '0x234',
        route: ExternalRoute.build('https://0x234.io')
    },
    {
        label: 'Privacy Policy',
        route: AppRoute.build(Route.PrivacyPolicy)
    },
    {
        label: 'Terms of Service',
        route: AppRoute.build(Route.TermsOfService)
    }
];

const productLinks = [
    {
        label: 'Xprt',
        route: ExternalRoute.build('https://xprt.dev')
    },
    {
        label: 'DoubleTime',
        route: ExternalRoute.build('https://doubletime.io')
    }
];

function openUserLoginDialog() {
    return openComponentDialog(defineAsyncComponent(() => import('components/UserLoginDialog.vue')), {
        headline: 'Shape the future for all Xprts&hellip;',
        message: `Your input is invaluable and helps us shape the platform for you. Become an Xprt to help us enhance the future of Xprt for everyone.`
    });
}
</script>

<template>
    <footer class="bg-black text-white q-px-lg">
        <div class="container-md q-px-lg q-py-lg">
            <div class="footer-sections">
                <div class="footer-section footer-section__logo">
                    <div class="logo-container">
                        <AppLogo/>
                    </div>
                    <div>
                        <p class="q-mt-sm q-mb-md">
                            by <a href="https://0x234.io" target="_blank">0x234</a>
                        </p>
                        <p class="q-mb-none">
                            Copyright &copy; 2024.<br/>All rights reserved.
                        </p>
                    </div>
                </div>
                <div class="footer-section footer-section__community">
                    <h6 class="text-h5 q-mt-none q-mb-md">Community</h6>
                    <ul>
                        <li v-for="link of communityLinks" :key="link">
                            <TextLink :label="link.label" :route="link.route"/>
                        </li>
                    </ul>
                </div>
                <div class="footer-section footer-section__company">
                    <h6 class="text-h5 q-mt-none q-mb-md">Company</h6>
                    <ul>
                        <li v-for="link of companyLinks" :key="link">
                            <TextLink :label="link.label" :route="link.route"/>
                        </li>
                    </ul>
                </div>
                <div class="footer-section footer-section__products">
                    <h6 class="text-h5 q-mt-none q-mb-md">Products</h6>
                    <ul>
                        <li v-for="link of productLinks" :key="link">
                            <TextLink :label="link.label" :route="link.route"/>
                        </li>
                    </ul>
                </div>
                <div class="footer-section footer-section__social">
                    <h6 class="text-h5 q-mt-none q-mb-md">Social</h6>
                    <div class="row q-gutter-sm">
                        <div>
                            <a href="https://linkedin.com/company/0x234" target="_blank" class="no-hover-decoration">
                                <q-icon size="md">
                                    <img src="~assets/brand_linkedin-white.svg" alt="0x234 on LinkedIn"/>
                                </q-icon>
                            </a>
                        </div>
                        <div>
                            <a href="https://twitter.com/xprtdev" target="_blank" class="no-hover-decoration">
                                <q-icon size="md">
                                    <img src="~assets/brand_x-white.svg" alt="Xprt on X"/>
                                </q-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<style scoped lang="scss">
footer {
    height: 100%;
    padding-bottom: 64px;
    padding-top: 64px;
}

.footer-sections {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: space-between;

    .footer-section {
        flex: 1;

        &.footer-section__logo {
            flex: 2;
            width: auto;
        }

        ul {
            margin-bottom: 0;
            padding-left: 0;

            li {
                list-style: none;
                padding-bottom: 4px;
            }
        }

        &.footer-section__social > div {
            justify-content: flex-start;
        }
    }

    @media(max-width: $breakpoint-sm-max) {
        justify-content: center;
        text-align: center;

        .footer-section__logo {
            flex: 0 0 100%;
            margin-top: 30px;
            order: 3;
        }

        .footer-section__community,
        .footer-section__company {
            order: 1;
            flex: 0 0 40%;
        }

        .footer-section__products,
        .footer-section__social {
            order: 2;
            flex: 0 0 40%;
        }

        .footer-section__social > div {
            justify-content: center !important;
        }
    }
}

.app-logo {
    text-align: left;
}
</style>
