<script setup>
import AppRoute from 'src/router/app-route';

const props = defineProps({
    link: {
        type: Object
    },
    hideIcons: {
        type: Boolean,
        default: false
    }
});

function isFunction(route) {
    return typeof route === 'function';
}

function isAppRoute(route) {
    return route instanceof AppRoute;
}

function itemAttrs(route) {
    if (isAppRoute(route)) {
        return { to: route, tag: 'router-link' };
    }
    if (isFunction(route)) {
        return {};
    }
    return { href: link.route.url, tag: 'a' };
}

function itemEvents(route) {
    if (isFunction(route)) {
        return { click: route };
    }
    return {};
}
</script>

<template>
    <q-item
        v-bind="itemAttrs(link.route)"
        v-on="itemEvents(link.route)"
        :title="link.title"
        :class="[ { 'coming-soon': link.comingSoonLabel } ]"
        :exact="link.exactRoute"
        clickable
        v-ripple
    >
        <q-item-section v-if="!hideIcons && link.icon" avatar>
            <q-icon :name="link.icon"/>
        </q-item-section>
        <q-item-section>
            <q-item-label>{{ link.title }}</q-item-label>
            <q-item-label v-if="link.comingSoonLabel" caption>Coming Soon</q-item-label>
        </q-item-section>
        <q-badge v-if="link.count" color="negative">{{ link.count }}</q-badge>
    </q-item>
</template>

<style lang="scss" scoped>
.q-item.coming-soon {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}

.q-item__label--caption {
    font-size: 65%;
    font-weight: 500;
    margin-top: 0;
    text-transform: uppercase;

    .q-router-link--active & {
        color: $white;
    }
}
</style>
