export default class ExternalRoute {
    url;

    constructor(url) {
        this.url = url;
    }

    static build(url) {
        return new this(url);
    }
}
