<script setup>
import AppRoute from 'src/router/app-route';
import ExternalRoute from 'src/router/external-route';
import { computed } from 'vue';

const props = defineProps({
    label: {
        type: String,
        required: true
    },
    route: {
        type: [ Object, Function ],
        required: false
    }
});

const aElementTag = computed(() => {
    if (props.route instanceof AppRoute) {
        return 'router-link';
    }
    return 'a';
})

const aElementEvents = computed(() => {
    if (typeof props?.route === 'function') {
        return {
            click: event => {
                event.preventDefault();
                props.route();
            }
        };
    }
    return {};
});

const aElementProperties = computed(() => {
    if (props.route instanceof AppRoute) {
        return {
            to: props.route
        };
    }
    if (props.route instanceof ExternalRoute) {
        return {
            href: props.route.url,
            target: '_blank'
        };
    }
    return {
        href: ''
    };
});
</script>

<template>
    <component :is="aElementTag" v-on="aElementEvents" v-bind="aElementProperties" class="text-link">{{ label }}</component>
</template>
